import React, { createContext, useReducer, useContext } from "react";
import {
  State,
  ContextType,
  mapFeatureProperties,
  mapEvents,
  feature,
} from "./types";
import Reducer from "./reducer";
import { log } from "console";

export const MapContext = createContext<ContextType | undefined>(undefined);

type Props = { children: React.ReactNode };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MapProvider = ({ children }: Props) => {
  const DefaultState: State = {
    isLoading: false,
    error: false,
    features: null,
    mapModule: null,
    searchInput: "",
    selectedLayer: "",
    drawerStatus: false,
    selectedFeature: null,
  };

  let initialState;
  //let data = JSON.parse(sessionStorage?.getItem("NavState")!);
  // console.log("data: ", data ? data : "null");
  //initialState = data ? { ...DefaultState, ...data } : DefaultState;
  initialState = DefaultState;

  //const MainReducer = Reducer();
  const [state, dispatch] = useReducer(Reducer, initialState);

  async function setError(error: string | {}) {
    dispatch({ type: "FAIL", payload: error });
  }

  async function setIsLoading(error: string | {}) {
    dispatch({ type: "FAIL", payload: error });
  }

  async function handleMapEvents(event: mapEvents, data: any) {
    try {
      // console.log('handleMapEvents: ', event, data)
      /* 
      const modifiedJson: Array<feature> = state.features!.layers.map(
          (feature: any) => {
            if (feature.id === data.id) {
              return { ...feature, properties: data.properties }
            }
            return feature
          }
        )
      */
      if (event === "SELECT_GEOFENCE") {
        // set selected feature
        dispatch({ type: "SET_SELECTED_FEATURE", payload: data.id });
        // open the drawer containing the details of the selected geofence
        toggleDrawerStatus();
        // the drawer should contains a form to edit it
        // handle changes by calling updateFeatureOnTheMap
      }
    } catch (err) {
      console.error(err);
    }
    return;
  }

  async function getFeatures(mapID: number) {
    try {
      const data = await fetch(`${process.env.REACT_APP_API_URL}/map/${mapID}`);
      let jsonData = await data.json();

      const allFeatures = { Feature: jsonData.Feature };

      /** Used when fetching the features from the database
      const allFeatures = jsonData.features.reduce((a: any, b: any) => {
        console.log("a", a);
        console.log("b", b);

        console.log("b.features", b.features);
        console.log("b.type", b.type);

        a[b.type] = b.features;
        return a;
      }, {}); */
      console.log("allFeatures", allFeatures);

      console.log(allFeatures.Feature);
      dispatch({
        type: "SET_FEATURES",
        payload: {
          layers: allFeatures.Feature || [],
          aps: [],
          doors: [],
          routes: [],
          navigationGrid: jsonData.navigationGrid,
          doorGrid: jsonData.doorGrid,
        },
      });
    } catch (error) {
      console.log("error: ", error);
      dispatch({ type: "FAIL", payload: error });
    }
  }

  function setMapModule(mapModule: State["mapModule"]) {
    // console.log("setMapModule: ", mapModule);
    dispatch({ type: "SET_MAP_MODULE", payload: mapModule });
  }

  const toggleDrawerStatus = () => dispatch({ type: "TOGGLE_DRAWER_STATUS" });

  // handle search input
  const handleSearchInput = (searchInput: string) => {
    // if search input is empty, invoke searchGetFence to cleat the paths and de-select the layer
    if (!searchInput) state.mapModule?.searchGeofence(searchInput);
    dispatch({ type: "SET_SEARCH_INPUT", payload: searchInput });
  };

  /**
   * select geofence using the search input
   */
  const searchFeatures = () => {
    // search validation
    // check if the geofence is valid and exists
    // if not, do nothing
    // if yes, search for features
    state.mapModule?.searchGeofence(state.searchInput.toLowerCase());
    dispatch({ type: "SET_SELECTED_LAYER", payload: state.searchInput });
  };

  /**
   * update the geofence data (other properties that are not related to the map style/view)
   * the changes should be applied to the state only until the user click on save changes
   * @param property eg: description, phone number, working hours, tags, sub-tags, etc
   * @param value
   * @param id feature id
   */
  const updateFeatureData = (property: string, value: any, id: number) => {};

  /**
   * update the geofence style (map style/view)
   * the changes should be applied to the state only until the user click on save changes
   * @param name
   * @param value
   * @param id
   */
  const updateFeatureOnTheMap = (
    property: mapFeatureProperties,
    value: any,
    id: number
  ) => {
    const update = state.mapModule?.updateFeatureOnTheMap(property, value, id);
    // check if the feature is updated successfully
    if (!update) return;
    // update the features with the new change
  };

  function saveToFile(data: any) {
    // download file on click
    const dlAnchorElem = document.createElement("a");
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(data));
    dlAnchorElem.setAttribute("href", dataStr);
    dlAnchorElem.setAttribute("download", "data.json");
    dlAnchorElem.click();
  }

  // const saveChanges = async () => {
  //   try {
  //     dispatch({ type: "PENDING" });
  //     let allFeatures = state.features?.layers;
  //     if (!allFeatures) throw new Error("No features selected");
  //     const feature = state.selectedFeature;
  //     if (!feature) throw new Error("No feature selected");
  //     const modified = allFeatures.filter((f: any) => f.id === feature.id)[0];
  //     if (modified) {
  //       modified.properties.style = feature.properties.style;
  //     }
  //     allFeatures = allFeatures.map((f: any) =>
  //       f.id === modified.id ? modified : f
  //     );
  //     saveToFile({
  //       type: "FeatureCollection",
  //       features: allFeatures,
  //       navigationGrid: state.features?.navigationGrid,
  //       doorGrid: state.features?.doorGrid,
  //     });
  //     dispatch({ type: "UPDATED_SUCCESSFULLY", payload: feature });
  //   } catch (err) {
  //     console.error(err);
  //     dispatch({ type: "FAIL", payload: err });
  //   }
  // };

  const saveChanges = async () => {
    try {
      dispatch({ type: "PENDING" });
      let feature = state.selectedFeature;
      if (!feature) throw new Error("No feature selected");
      let externalData = feature.externalData!;

      // Handle new media if any
      if (feature.newMedia && feature.newMedia.length) {
        let newImages = [];
        let newVideos = [];

        // Upload the new media to MinIO
        for (let media of feature.newMedia || []) {
          let URL = `${process.env.REACT_APP_API_URL}/minio/upload`;
          // post body as multipart/form-data
          let formData = new FormData();
          formData.append("file", media.file);

          let res = await fetch(URL, {
            method: "POST",
            body: formData,
          });
          console.log("res", res);
          let data = await res.text();
          console.log("data", data);

          if (media.type === "image") {
            newImages.push(`${process.env.REACT_APP_MINIO_BASE_URL}/${data}`);
          } else {
            newVideos.push(`${process.env.REACT_APP_MINIO_BASE_URL}/${data}`);
          }
        }

        console.log("newImages", newImages);
        console.log("newVideos", newVideos);

        // Add the new media to externalData
        externalData = {
          ...externalData,
          images: [...(externalData?.images || []), ...newImages],
          videos: [...(externalData?.videos || []), ...newVideos],
        };

        dispatch({
          type: "SET_FEATURE_EXTERNAL_DATA",
          payload: {
            value: externalData,
          },
        });

        // Clear newMedia
        dispatch({ type: "SET_FEATURE_NEW_MEDIA", payload: { value: [] } });
      }

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/map/${feature?.id}/properties`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            properties: feature?.properties,
            externalData: externalData,
          }),
        }
      );
      await res.json();
      dispatch({ type: "UPDATED_SUCCESSFULLY", payload: feature });
    } catch (err) {
      console.error(err);
      dispatch({ type: "FAIL", payload: err });
    }
  };

  const cancelChanges = () => {};

  // set the external data property for the selected feature
  const setSelectedFeatureExternalData = (propertyData: any) => {
    // console.log(propertyData);

    dispatch({
      type: "SET_FEATURE_EXTERNAL_DATA",
      payload: { value: propertyData },
    });

    // console.log(state.selectedFeature);
    // console.log(state.features);
  };

  // set the properties property for the selected feature
  const setSelectedFeatureProperty = (property: any, propertyData: any) => {
    // console.log(property, propertyData);

    let temp = state.selectedFeature?.properties;
    temp = { ...temp!, [property]: propertyData };
    // console.log(temp);

    dispatch({
      type: "SET_FEATURE_PROPERTY",
      payload: { value: temp, id: state.selectedFeature?.id! },
    });

    // console.log(state.selectedFeature);
  };

  // set the files to be uploaded for the selected feature
  const setSelectedFeatureNewMedia = (media: feature["newMedia"]) => {
    // console.log(media);
    dispatch({
      type: "SET_FEATURE_NEW_MEDIA",
      payload: { value: media },
    });
    // console.log(state.selectedFeature);
  };

  // const setAllFeatures = (newFeatures: any) => {
  //   dispatch({
  //     type: "SET_FEATURES",
  //     payload: {
  //       layers: newFeatures || [],
  //       aps: [],
  //       doors: [],
  //       routes: [],
  //     },
  //   });
  //   // console.log(state.features);
  // };

  return (
    <MapContext.Provider
      value={{
        error: state.error,
        isLoading: state.isLoading,
        features: state.features,
        mapModule: state.mapModule,
        searchInput: state.searchInput,
        drawerStatus: state.drawerStatus,
        selectedLayer: state.selectedLayer,
        selectedFeature: state.selectedFeature,
        setIsLoading,
        setError,
        getFeatures,
        setMapModule,
        toggleDrawerStatus,
        handleSearchInput,
        searchFeatures,
        handleMapEvents,
        updateFeatureOnTheMap,
        updateFeatureData,
        saveChanges,
        cancelChanges,
        setSelectedFeatureExternalData,
        setSelectedFeatureProperty,
        setSelectedFeatureNewMedia,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export function useMap() {
  const context = useContext(MapContext);
  if (!context) throw new Error("You need to wrap MapProvider.");
  return context;
}

export default MapProvider;
