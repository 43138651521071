import { useRef, useEffect } from "react";
import { useMap } from "../context";
import { useParams } from "react-router-dom";

const IndoorComponent = ({ module }: any) => {
  const mapRef = useRef(null);
  const { features, getFeatures, setMapModule, handleMapEvents } = useMap();
  let { mapId } = useParams();

  // load features on mount
  useEffect(() => {
    getFeatures(Number(mapId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapId]);

  // mount map when module ref and features is available
  useEffect(() => {
    if (module.mount && mapRef.current && features) {
      const featuresCopy = JSON.parse(JSON.stringify(features));
      module.mount(mapRef.current, featuresCopy, handleMapEvents);
      setMapModule(module);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module, features]);

  return (
    <div
      id="map-container"
      style={{
        position: "absolute",
        zIndex: 0,
        backgroundColor: "#666",
        height: "100vh",
        width: "100vw",
      }}
      ref={mapRef}
    ></div>
  );
};

export default IndoorComponent;
