import { useEffect, useState } from "react";
import "./MapPicker.css";
import Modal from "react-modal";
import Select, { CSSObjectWithLabel } from "react-select";
import { useNavigate } from "react-router-dom";
import { IndoorMap } from "../types/IndoorMap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "fit-contet",
    minWidth: "30%",
    overflow: "none",
    maxHeight: "30vh",
    height: "fit-content",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
  },
};

export const MapPicker = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [maps, setMaps] = useState<IndoorMap[]>([]);
  const [selectedMap, setSelectedMap] = useState<IndoorMap | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace this URL with your actual API endpoint
        const response = await fetch("https://navbe.dev.hoopoe.digital/map");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result: IndoorMap[] = await response.json();
        console.log("result", result);

        setMaps(result);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch map data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div id="map-picker">
      <Modal isOpen={true} ariaHideApp={false} style={customStyles}>
        <h1>Map picker</h1>
        <div className="container">
          <div className="row">
            <p style={{ alignSelf: "center" }}>Select a map</p>
            <Select
              placeholder="Select a map"
              value={
                selectedMap && {
                  value: selectedMap.id,
                  label: selectedMap.name,
                }
              }
              options={maps.map((map) => ({
                value: map.id,
                label: map.name,
              }))}
              onChange={(selectedOption) =>
                selectedOption?.value &&
                setSelectedMap(
                  maps.find((map) => map.id === selectedOption.value) || null
                )
              }
            />
          </div>
          <div className="row">
            <button
              className="map-picker-select"
              onClick={() => selectedMap && navigate(`/${selectedMap.id}`)}
            >
              Select
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
